import { useEffect, useState } from 'react';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { TGroupedByActivitySeriesBe } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/GroupedByActivitySeries.type';
import { getActivityById } from 'activities/services/activities.service';
import { useSelector } from 'react-redux';
import { IState } from 'types/state.interface';
import { compact, isEmpty } from 'lodash';
import * as activityService from 'activities/services/activities.service';
import { TActivityRawValue } from 'activities/pages/types/activity.type';
import { toCenterOfWeek } from 'activities/utils';

export const toActivityInitialValues = ({
  activityType,
  duration,
  startDate,
  values,
  tags,
}: {
  activityType: string;
  duration: number;
  startDate: string;
  values: TActivity2Be['values'];
  tags?: TActivity2Be['tags'];
}): Record<string, TActivityRawValue> => {
  const activityValue: Record<string, TActivityRawValue> = {
    activityType: compact([activityType]),
    duration,
    ...values.reduce(
      (results: Record<string, TActivity2Be['values'][number]['value']>, val: TActivity2Be['values'][number]) => ({
        ...results,
        [val.extId]: val.value,
      }),
      {},
    ),
  };
  if (startDate) {
    activityValue.weeks = [toCenterOfWeek(startDate).valueOf()];
  }
  if (tags) {
    activityValue.tags = tags.map((tag) => ({
      value: tag._id.toString(),
      label: tag.name,
    }));
  }
  return activityValue;
};

export const toUniqActivityValues = (series: TGroupedByActivitySeriesBe) => {
  const activityValues = series?.allValues?.map(({ activityValue }) => activityValue) ?? [];
  const extIdsInValues: string[] = [];
  const hasMutlipleValues: string[] = [];
  const hasValues: string[] = [];
  activityValues.forEach((item, itemIndex: number) => {
    if (extIdsInValues.includes(item.extId)) {
      hasMutlipleValues.push(item.extId);
    } else {
      extIdsInValues.push(item.extId);
    }
    if (hasValues.includes(item.extId)) {
      delete activityValues[itemIndex];
    }
    if (!isEmpty(item.value)) {
      hasValues.push(item.extId);
    }
  });
  return { activityValues, hasMutlipleValues };
};

export const useActivityGetter = ({ activityId }: { activityId: string }) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [activity, setActivity] = useState<undefined | TActivity2Be>();
  const [initialValues, setInitialValues] = useState<undefined | Record<string, TActivityRawValue>>();
  const [activitiesInSameTracks, setActivitiesInSameTracks] = useState<TActivity2Be[]>([]);

  useEffect(() => {
    if (activityId) {
      const doGetActivity = async () => {
        setLoading(true);
        if (!organizationId || !activityId) {
          setActivity(undefined);
          setLoading(false);
          return;
        }
        const result = await getActivityById(organizationId, activityId);
        const { activity } = result;
        if (activity && activity.metadata) {
          const ssp = {
            groupBy: 'FLAT',
            matchType: 'ALL',
            page: 1,
            filters: {
              activitySeriesId: {
                values: [activity.activitySeriesGroup.activitySeriesId],
              },
              track: {
                values: [activity.track],
              },
            },
          };
          setLoading(true);
          const activitiesInSameTracks: TActivity2Be[] = (await activityService.getActivities(organizationId, ssp))
            .results;
          if (activitiesInSameTracks) {
            setActivitiesInSameTracks(activitiesInSameTracks.filter(({ _id }) => _id !== activity._id));
          }

          setInitialValues(
            toActivityInitialValues({
              activityType: activity.metadata.activityType,
              duration: activity.metadata.length,
              startDate: activity?.metadata.startDate,
              values: activity.values,
              tags: activity.tags,
            }),
          );
        }

        setActivity(activity);
        setLoading(false);
      };
      doGetActivity();
    } else {
      setActivity(undefined);
    }
  }, [activityId]);

  return {
    activity,
    initialValues,
    activitiesInSameTracks,
    loading,
  };
};

export const useActivitySeriesGetter = ({ activitySeriesId }: { activitySeriesId?: string }) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [activitySeries, setActivitySeries] = useState<
    undefined | (TGroupedByActivitySeriesBe & { tags: TActivity2Be['tags'] })
  >();
  const [activityValues, setActivityValues] = useState<undefined | TActivity2Be['values']>();
  const [multipleValuesExtIds, setMultipleValuesExtIds] = useState<string[]>([]);

  useEffect(() => {
    if (!organizationId || !activitySeriesId) return;

    const doGetActivitySeries = async () => {
      const ssp = {
        groupBy: 'ACTIVITY_SERIES',
        matchType: 'ALL',
        page: 1,
        limit: 1,
        filters: {
          activitySeriesId: {
            values: [activitySeriesId],
          },
        },
      };
      setLoading(true);
      const series = (await activityService.getActivities(organizationId, ssp)).results?.[0];
      setActivitySeries(series);
      const { activityValues, hasMutlipleValues } = toUniqActivityValues(series);
      setActivityValues(activityValues as TActivity2Be['values']);
      setMultipleValuesExtIds(hasMutlipleValues);
      setLoading(false);
    };
    doGetActivitySeries();
  }, [activitySeriesId]);

  return {
    activitySeries,
    activityValues,
    multipleValuesExtIds,
    loading,
  };
};
