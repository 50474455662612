import React, { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { compact, sortBy } from 'lodash';
import { Button, Flex } from 'antd';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import './WeeksSelector.scss';
import { toCenterOfWeek } from 'activities/utils';

dayjs.extend(isoWeek);

type TWeeksSelectorProps = {
  value: number[];
  onChange: (value: number[]) => void;
  allowMultiple?: boolean;
  allowClear?: boolean;
  labelRender?: (week: number) => string;
  disabledWeeks?: number[];
};

export default function WeeksSelector(props: TWeeksSelectorProps) {
  const { value = [], onChange, allowMultiple, allowClear, labelRender, disabledWeeks } = props;
  const defaultYear = dayjs(sortBy(value)[0]).year();
  const [year, setYear] = useState(defaultYear);
  const formattedValue = useMemo(() => {
    return value.map((val) => toCenterOfWeek(val).valueOf());
  }, [value]);

  const onSelect = (week: Dayjs) => {
    let selected;
    const weekValue = toCenterOfWeek(week).valueOf();
    if (value.includes(weekValue)) {
      selected = value.filter((val) => val !== weekValue);
    } else {
      selected = [...value, weekValue];
    }
    if (!allowClear && !selected.length) return;
    onChange(allowMultiple ? selected : compact([selected[selected.length - 1]]));
  };

  const weeks = useMemo(() => {
    const startOfYear = dayjs(year.toString(), 'YYYY');

    const _weeks: Dayjs[] = [];
    for (let i = 0; i < 54; i += 1) {
      if (startOfYear.add(i, 'week').endOf('week').year() === year) {
        _weeks.push(startOfYear.add(i, 'week'));
      }
    }
    return (
      <div className="weeks-selector__weeks">
        {_weeks.map((week) => {
          const weekValue = toCenterOfWeek(week).valueOf();
          const weekIsDisabled = disabledWeeks?.includes(weekValue);
          return (
            <Button
              type="primary"
              ghost={!weekIsDisabled && !formattedValue.includes(weekValue)}
              onClick={() => onSelect(week)}
              shape="round"
              className="weeks-selector__week"
              key={week.valueOf()}
              disabled={weekIsDisabled}
            >
              {typeof labelRender === 'function' ? labelRender(week.valueOf()) : week.format('Wo')}
            </Button>
          );
        })}
      </div>
    );
  }, [year, value]);

  return (
    <div className="weeks-selector">
      <Flex style={{ userSelect: 'none' }}>
        <CaretLeftFilled onClick={() => setYear(year - 1)} />
        <b className="te-ml-2 te-mr-2">{year}</b>
        <CaretRightFilled onClick={() => setYear(year + 1)} />
      </Flex>
      {weeks}
      {allowMultiple && <div className="te-mt-2">{value.length} weeks selected</div>}
    </div>
  );
}
