import React, { useContext, useEffect } from 'react';
import { EAppId, EAuth } from '@timeedit/types/lib/enums';
import './DataManager.scss';
import { useDispatch, useSelector } from 'react-redux';
import { organizationSelector } from '../slices/organization.slice';
import { StandardLayout } from '@timeedit/ui-components';
import { componentMap, getNavigation } from '../routes/routes';
import { EAppEnv } from '@timeedit/types/lib/enums/appEnv.enum';
import { getVisibility } from '../utils/authentication';
import { fetchMapping } from '../slices/integration.slice';
import { fetchPathwayForms } from '../study-combinations/slices/pathway.slice';
import { fetchCombinationsForms } from '../study-combinations/slices/combinationStudents.slice';
import { fetchObjectRelationships } from '../slices/objectRelationships.slice';
import { ConfigProvider, Spin, ThemeConfig } from 'antd';
import { defaultTheme } from '@timeedit/ui-components/lib/src/assets/theme/default';
import { useFetchRequiredData } from 'utils/useFetchRequiredData';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';
import { AuthContext } from '@timeedit/ui-components/lib/src/components/AuthWrapper/AuthWrapper';

function DataService() {
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const authUser = useSelector((state: any) => state.auth.user);
  const authState = useContext(AuthContext)?.authState;
  const requiredDataResult = useFetchRequiredData();

  const logout = useContext(AuthContext)?.logout;

  useEffect(() => {
    // Fetch data for study combinations,
    // if it can be disabled add a guard to check if study combinations is in permissions
    if (getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser)) {
      dispatch(fetchPathwayForms());
      dispatch(fetchCombinationsForms());
    }
    if (getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user, TE_PREFERENCES::user'] }, authUser)) {
      dispatch(fetchObjectRelationships('TE_PREFERENCES'));
    }
  }, []);

  useEffect(() => {
    if (organization?.id) {
      dispatch(fetchMapping());
    }
  }, [organization?.id]);

  const { dmActivityCreateTemplateManagement } = useAppFeatureFlags();

  if (authState === EAuth.NOT_AUTHENTICATED) {
    return <>You are not authenticated</>;
  }

  const havePermission =
    getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser) ||
    getVisibility([], { scopes: ['TE_ACTIVITIES::user'] }, authUser) ||
    getVisibility([], { scopes: ['TE_ALLOCATE::user'] }, authUser);

  if (!havePermission) {
    return <>You do not have permission to view this page</>;
  }

  const navigation = getNavigation(authUser, { dmActivityCreateTemplateManagement });

  if (requiredDataResult.status === 'loading') {
    return <Spin />;
  }

  return (
    <div className="inner-content--wrapper">
      <div className="page page--pathway-service">
        <ConfigProvider theme={defaultTheme as unknown as ThemeConfig}>
          {/* TODO: StandardLayout does not support big content, apply overflow-y:scroll in the content ui-components */}
          {organization && (
            <StandardLayout
              currentProduct={EAppId.TE_DATA_MANAGER}
              logoutFn={() => logout?.()}
              navigation={navigation}
              componentMap={componentMap}
              appEnv={(process.env.REACT_APP_TE_APP_ENV as EAppEnv) || EAppEnv.PRODUCTION}
              organization={organization}
              user={authUser}
              routerBasename={process.env.REACT_APP_BRANCH_PREVIEW_BASE_NAME}
            />
          )}
        </ConfigProvider>
      </div>
    </div>
  );
}

export default DataService;
