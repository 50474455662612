import React, { useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { ActivityInfo } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks, ActivityWeeks } from './ActivityValues';
import { useActivityGetter } from './ActivityDrawer.hook';
import { DrawerProps } from 'antd';
import intl from 'i18n/intl';
import { updateActivitiesValues } from 'activities/services/activities.service';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import ActivityTags from './ActivityTags';
import { toCenterOfWeek } from 'activities/utils';

const language = intl.messages as Record<string, string>;

type TActivityDrawer = DrawerProps & {
  activityId?: string;
  onClose?: () => void;
};
function ActivityDrawer(props: TActivityDrawer) {
  const { open, onClose } = props;
  const { loading, activity, initialValues, activitiesInSameTracks } = useActivityGetter({
    activityId: props.activityId!,
  });
  const editable = useMemo(() => {
    if (!activity) return false;
    return ![EActivityStatus.IN_REVIEW].includes(activity?.activityStatus);
  }, [activity]);
  const [saving, setSaving] = useState(false);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activity || !activity.formId) return;
    setSaving(true);
    await updateActivitiesValues(activity.formId.toString(), activity._id.toString(), 'FLAT', updatedValues);
    setSaving(false);
    if (typeof onClose === 'function') {
      document.dispatchEvent(new CustomEvent(ActivityEvents.REFETCH_ACTIVITIES_TABLE));
      onClose();
    }
  };

  const disabledWeeks = useMemo(() => {
    if (!activitiesInSameTracks) return [];
    return activitiesInSameTracks.flatMap((activity) => {
      return [
        toCenterOfWeek(activity.metadata.startDate).valueOf(),
        toCenterOfWeek(activity.metadata.endDate).valueOf(),
      ];
    });
  }, [activitiesInSameTracks]);

  return (
    <ActivityDrawerWrapper
      open={open}
      onClose={onClose}
      title="Activity"
      initialValues={initialValues}
      primaryObject={activity?.metadata?.primaryObject}
      editable={editable}
      loading={loading}
      saving={saving}
      onSave={onSave}
    >
      <>
        <ActivityInfo
          editable={editable}
          loading={loading}
          activityType={activity?.metadata.activityType}
          totalTracks={activity?.metadata.totalTracks}
        />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activity?.values}
          track={activity?.metadata?.track}
          title={language.track_info}
        >
          <ActivityTracks track={activity?.metadata.track} />
          <ActivityWeeks editable disabledWeeks={disabledWeeks} />
        </ActivityValues>
        <ActivityTags formId={activity?.formId.toString()} editable={editable} />
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivityDrawer;
